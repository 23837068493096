import React, { useState } from "react";
import { Link } from "gatsby";
import { Container, Row, Col, Form } from "react-bootstrap";
import ScrollAnimation from "react-animate-on-scroll";
import "./AboutBoost.scss";
import parse from 'html-react-parser';
import { AltSiteName } from "../../utils";
import { getSrc } from "gatsby-plugin-image"
import GetCTA from "../../getcta";
import getVideoId from "get-video-id";
import PlayVideo from "../../Play/PlayVideo";

const FullWidthTile = (props) => {
  const [isPlay, setPlay] = useState(false);
  const videoid = props.video && getVideoId(props.video);
  return (
    <React.Fragment>
      
      <section className={`about-boost full-width-tile ${props.bgcolor}`}>        
        <div className="about-box">
          <div className="about-block-img">
            <picture className="img-zoom">              
              <img src={getSrc(props.image.url_sharp)}  alt={props.image.alternativeText?props.image.alternativeText:props.title+AltSiteName}/>            
            </picture>
            {props.video?
                            <button
                            type="button"
                            className="btn-play"
                            onClick={(e) => {
                            setPlay(true);}}>
                            <i className="icon-play"></i>
                            </button>
                            :""}
          </div>
          <Container>
            <Row>
              <Col md="12" className="ms-lg-auto">
                <div className="about-boost-content">
                  <span className="small-title">{props.title}</span>                 
                    {parse(props.content)}                                    
                    {props.customlink?
                        <Link to={props.customlink} className="link-text">{props.label}</Link>
                        :
                        <GetCTA Label={props.label} link={props.link && props.link.Primary_URL} class="link-text"/>
                    }
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </section>
      {videoid && props.video &&
      <PlayVideo
        isOpen={isPlay}
        isCloseFunction={setPlay}
        videoId={videoid.id}
        isAutoPlay={1}
      />
      }            
    </React.Fragment>
  );
};
export default FullWidthTile;
