import React, { useState } from "react";
import { Link } from "gatsby";
import Select from "react-dropdown-select";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import ScrollAnimation from "react-animate-on-scroll";
import "./ValuationInfo.scss";
import BookAViewingForm from '../../forms/book-a-viewing';
import MakeanEnquiryForm from '../../forms/make-an-enquiry';

const BookAViewing = (props) => {
  return (
    <React.Fragment>
      <section className="valuation-info book-appointment-form">
        <Container>
          <Row>
            <Col>              
              

                <div className="valuation-box book">
                  <Link className="icons">
                    <i className="home">
                    </i>
                  </Link>
                  <h3>{props.enqtype=="enquiry"?"Make an Enquiry":"Book a Viewing"}</h3>
                  {props.enqtype=="enquiry"?
                  <p>Are you interested in this property? Fill in the form to know more by make an enquiry.</p>
                  :
                  <p>Are you interested in this property? Fill in the form to know more by booking a viewing.</p>
                  }
                  {props.enqtype=="enquiry"?
                  <MakeanEnquiryForm to_email_id={props.toemail} property_url={props.property_url} property_id={props.id} page_url={props.property_url} property_title={props.title ? props.title : ''} property_img={props.images ? props.images.url : ''} property_address={props.address_details ? props.address_details : ''} property_bedrooms={props.bedrooms ? props.bedrooms : ''} property_bathrooms={props.bathrooms ? props.bathrooms : ''} officeurl={props.officeurl} />
                  :
                  <BookAViewingForm to_email_id={props.toemail} property_url={props.property_url} property_id={props.id} page_url={props.property_url} property_title={props.title ? props.title : ''} property_img={props.images ? props.images.url : ''} property_address={props.address_details ? props.address_details : ''} property_bedrooms={props.bedrooms ? props.bedrooms : ''} property_bathrooms={props.bathrooms ? props.bathrooms : ''} officeurl={props.officeurl} />
                  }
                </div>
              
              <div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </React.Fragment>
  );
};
export default BookAViewing;
