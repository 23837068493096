import React, { useState } from "react";
import { Link } from "gatsby";
import Select from "react-dropdown-select";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import ScrollAnimation from "react-animate-on-scroll";
import "./ValuationInfo.scss";
import MemberContactForm from '../../forms/contact';
const MemberContact = (props) => {
  const Departments = [
    { value: "Option", label: "Option" },
    { value: "Option1", label: "Option1" },
    { value: "Option2", label: "Option2" },
    { value: "Mortgage type", label: "Mortgage type" },
  ];
  const Branch = [
    { value: "Option", label: "Option" },
    { value: "Option1", label: "Option1" },
    { value: "Option2", label: "Option2" },
    { value: "Select branch", label: "Select branch" },
  ];
  return (
    <React.Fragment>
      <section className="valuation-info">
        <Container>
          <Row>
            <Col>
              
              

                <div className="valuation-box book">
                  <Link className="icons">
                    <i className="home">
                    </i>
                  </Link>
                  <h3>Contact {props.name ? ' - '+props.name : ""}</h3>
                  <p>Please complete the form below and we will be in touch.</p>
                  <MemberContactForm to_email_id={props.email} staff_name={props.name} subject="Staff" formtype="Staff Contact" />
                </div>
              
              <div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </React.Fragment>
  );
};
export default MemberContact;
