import React, { useState, useEffect } from "react";
import { Link } from "gatsby"
import { Container, Row, Col, Form, Button, Tabs, Tab } from "react-bootstrap"
// import Trustpilot from '../../../images/trustpilot.svg'
import Slider from "react-slick";
import "./Banner.scss";
import parse from 'html-react-parser';
// import { getSrc } from "gatsby-plugin-image"
// import { AltSiteName } from "../../utils"
import SearchInputBox from '../../predictive-search/search-areas';
import GoogleRating from '../../StaticModules/GoogleRating/GoogleRating';
import { navigate } from "@reach/router";
import $ from 'jquery';
// import TrustPilotBadge from "../../trust-pilot-badge";
import loadable from '@loadable/component';
import HTMLParser from 'html-react-parser';
// import ArrowWhite from '../../../images/arrow-white.svg';
// import xBlue from '../../../images/x-blue.svg';
import xGold from '../../../images/x-gold.svg';
import BannerVideo from "./BannerVideo";
import Select from "react-dropdown-select"
import { useMediaQuery } from "../../../hooks/useMediaQuery";
import Autosuggest from "react-autosuggest";

const settings = {
  dots: true,
  speed: 800,
  infinite: false,
  arrows: false,
  mobileFirst: true,
  centerMode: false,
  responsive: [
    {
      breakpoint: 3020,
      settings: {
        arrows: false,
        slidesToShow: 1
      }
    },
    {
      breakpoint: 1199,
      settings: {
        arrows: false,
        slidesToShow: 1
      }
    },
    {
      breakpoint: 991,
      settings: {
        dots: false,
        slidesToShow: 1
      }
    },
    {
      breakpoint: 400,
      settings: {
        arrows: false,
        slidesToShow: 1
      }
    }
  ]
};
const AllTypes = [
  { value: null, label: "All Properties" },
  { value: "house", label: "House" },
  { value: "apartment", label: "Apartment" },
  { value: "bungalow", label: "Bungalow" },
  { value: "maisonette", label: "Maisonette" },
  { value: "development-plot", label: "Development Plot" },
  { value: "land", label: "Land" },
];

const Banner = (props) => {
  const [currentTab, setCurrentTab] = useState("buy")
  const [propertyval, setPropertyVal] = useState(null);
  const TrustPilotBadge = loadable(() => import('../../trust-pilot-badge'))
  TrustPilotBadge.preload()
  const isMobile = useMediaQuery("(max-width: 767px)");

  const tabList = [
    { title: "Buy", key: "buy", slug: "/properties-for-sale/" },
    { title: "Rent", key: "rent", slug: "/properties-to-rent/" },
    { title: "New Homes", key: "new-homes", slug: "/new-homes-for-sale/" },
  ]
  const onSubmitSearch = () =>{
    let actionSlug = tabList.find((element) => element.key == currentTab).slug; 
    var searcValue = $(`.banner-content .tab-form-${currentTab} .searchVal`).val().split(', ').join('-').replace(/ /g, "-").replace(/'/g, "").toLowerCase(); 
    if (searcValue !== "") {
      actionSlug = actionSlug + searcValue + "/"
    } else {
      actionSlug = actionSlug + "essex-and-kent/"
    }  
    if(propertyval){
      actionSlug = actionSlug + propertyval + "/"
    }

    navigate(actionSlug);
  }

  useEffect(() => {
    $(".banner-content .react-autosuggest__input").on("keyup", function () {
      var opencontainer = $('.banner-content .react-autosuggest__suggestions-container--open').length;
      setTimeout(function () {
        if (opencontainer > 0) {
          $('.maincarousel').addClass('banner-search-reveal')
        } else {
          $('.maincarousel').removeClass('banner-search-reveal')
        }
      }, 500);
    });  
  });

  // 
  const sideBannerBtn = () => {
    $(".appraisal-bar-sidebar").addClass("active");
    $(".left-block-sidebar").addClass("display");
  }

  const sideBannerBtnClose = () => {
    $(".appraisal-bar-sidebar").removeClass("active");
    $(".left-block-sidebar").removeClass("display");
  }
  //

  //console.log ("vvvvvvvvvvvv", props.GQLConfig)

  //console.log(props.GQLConfig?.Info_Toogle, "Toogle")
  const inputProps = {
    placeholder: 'Street, area or postcode',
    value: "",
    onChange: ""
  };
  return (
    <React.Fragment>
      <div className="val-banner-head responsive-val-head d-block d-lg-none">
        <Link to="/property-valuation/">
          CLICK HERE TO BOOK A <span>FREE</span> VALUATION
        </Link>
      </div>
      <div className="banner maincarousel">
        <Slider className="banner-imgslider d-block" {...settings}>
          {props.slides && props.slides.map((slide, index) => {
            return <>
              <div className="banner-item">
                <Container>
                  <Row>
                    <Col lg='9'>
                      <div className="val-banner-head d-lg-block d-none">
                        <Link to="/property-valuation/">
                          CLICK HERE TO BOOK A <span>FREE</span> VALUATION
                        </Link>
                      </div>
                      <div className="banner-content">

                        <div className="banner-block">

                          <h1>{slide.Slider_Title}</h1>
                          {parse(slide.Slider_Content)}

                        </div>
                        {isMobile && props.renderComponent ?
                          <BannerVideo slideTitle={slide.Slider_Title} slideImage={slide.Slider_Image} videoUrl={slide.banner_video_url} bannerLink={slide.banner_link} />
                          : <></>
                        }

                        <Tabs
                          defaultActiveKey={currentTab}
                          id="uncontrolled-tab-example"
                          className="mb-3"
                           onSelect={(val)=>{ setCurrentTab(val) }}
                        
                        >
                          {tabList.map((tab, k) => {
                            return (
                              <Tab eventKey={tab.key} title={tab.title} key={k}>
                                <Form className={`search-banner-${index} tab-form-${tab.key}`} id={`search-banner-form${index}`}>
                                {props.renderComponent ? <SearchInputBox /> :
                                <Autosuggest
                                suggestions={""}
                                inputProps={inputProps}
                      
                              />
                              }

                                  <div className="custom-selectbox before-border "> 
                                    <Form.Group controlId="buy-checkbox">

                                      <Select 
                                        onChange={e => {setPropertyVal(e[0].value)}}
                                        className={"form-slect type-select"}   
                                        name="type"
                                        options={AllTypes}
                                        placeholder="All Properties"
                                        searchable={false}
                                        defaultValue={AllTypes.find(obj => obj.value === propertyval)}
                                        // dropdownPosition="top"
                                      /> 
                                        
                                    </Form.Group>

                                  </div>
                                  <Button className="search_btn" onClick={onSubmitSearch} variant="primary" type="button">Find a property</Button>
                                </Form>
                              </Tab> 
                            ) 
                          })}
                        </Tabs>

                        {/* {index === 1 &&
                          <Form className={`search-banner-${index}`} id={`search-banner-form${index}`}> 
                            <SearchInputBox />
                            <div className="custom-checkbox one">
                              <Form.Group controlId="buy-checkbox-one">
                                <Form.Check type="radio" name="search-property-one" label="Buy" value="buy" />
                              </Form.Group>
                              <Form.Group controlId="rent-checkbox-one">
                                <Form.Check type="radio" name="search-property-one" label="Rent" value="rent" />
                              </Form.Group>
                            </div>
                            <Button className="search_btn_one" variant="primary" type="button">Find a property</Button>
                          </Form>
                        }
                        {index === 2 &&
                          <Form className={`search-banner-${index}`} id={`search-banner-form${index}`}> 
                            <SearchInputBox />
                            <div className="custom-checkbox two">
                              <Form.Group controlId="buy-checkbox-two">
                                <Form.Check type="radio" name="search-property-two" label="Buy" value="buy" />
                              </Form.Group>
                              <Form.Group controlId="rent-checkbox-two">
                                <Form.Check type="radio" name="search-property-two" label="Rent" value="rent" />
                              </Form.Group>
                            </div>
                            <Button className="search_btn_two" variant="primary" type="button">Find a property</Button>
                          </Form>
                        } */}
                        <GoogleRating />
                      </div>
                    </Col>

                  </Row>

                  {!isMobile && props.renderComponent ?
                    <BannerVideo slideTitle={slide.Slider_Title} slideImage={slide.Slider_Image} videoUrl={slide.banner_video_url} bannerLink={slide.banner_link} />
                  : <></>
                  }

                </Container>
              </div>
            </>
          })}


        </Slider>
      </div>

      {props.Show_Holidays_Banner && (
        props.GQLConfig?.Info_Toogle &&
        <div className="appraisal-bar-sidebar">
          <div className="left-block-sidebar" onClick={sideBannerBtn}>
            <p>{props.GQLConfig?.Sidebar_Title}</p>
          </div>
          {props.GQLConfig?.Sidebar_Title_Description &&
            <div className="right-block-sidebar">
              <h3>{props.GQLConfig?.Sidebar_Title}</h3>
              {HTMLParser(props.GQLConfig?.Sidebar_Title_Description)} <img className="close-icon" src={xGold} alt="Close" onClick={sideBannerBtnClose} />
            </div>
          }
        </div>
      )
      }

    </React.Fragment>
  )
}
export default Banner
