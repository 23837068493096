import React, {useState} from "react"
import { getSrc } from "gatsby-plugin-image"
import { Link } from "gatsby"
import { AltSiteName } from "../../utils"

const BannerVideo = (props) => {
    let [mute, setMute] = useState(true);
    const bannerVideoContent = (
        <div className="main-banner-embed d-block d-xl-block align-items-xl-stretch dd main-video-banner full-baner">
            {props.videoUrl && 
                <video id="slider-banner-video" width="100%" height="100%" autoPlay muted={mute} playsInline loop className="video-banner d-block">
                <source src={`${props.videoUrl}`} type="video/mp4"/>                           
                </video>
            }
            <picture>                                
                <img className={`${props.videoUrl ? 'd-none' : 'd-block'}`} src={getSrc(props.slideImage.url_sharp) } alt={props.slideImage.alternativeText?props.slideImage.alternativeText:props.slideTitle+AltSiteName} />
            </picture>
        </div>
    );
    return (
        <React.Fragment>
            <div className="banner-img">
                {props.bannerLink ? 
                   (<Link to={props.bannerLink}>{bannerVideoContent}</Link>) 
                 : (bannerVideoContent)
                }
                <div className="main-banner-start d-block">
                    {props.videoUrl &&
                        <div className="banner-btn">
                            <strong class="video-btn video-mute-btn" onClick={() => { setMute(!mute) }}><i class={`${(mute) ? 'mute-icon' : 'unmute-icon'} `}></i></strong>
                        </div>
                    }
                </div>
            </div>
        </React.Fragment>
    )
}
export default BannerVideo