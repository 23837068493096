import React, { useState } from "react";
import { Link } from "gatsby";
import Select from "react-dropdown-select";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import ScrollAnimation from "react-animate-on-scroll";
import "./ValuationInfo.scss";
import SendToFriendForm from '../../forms/sendfrnd-form';

const SendToFriend = (props) => {
  return (
    <React.Fragment>
      <section className="valuation-info book-appointment-form">
        <Container>
          <Row>
            <Col>              
              

                <div className="valuation-box book">
                  <Link className="icons">
                    <i className="home">
                    </i>
                  </Link>
                  <h3>Send to friend</h3>
                  <p>Please submit the following form and share this property with your friend.</p>
                  <SendToFriendForm to_email_id={props.toemail} property_url={props.property_url} property_id={props.id} page_url={props.property_url} property_title={props.title ? props.title : ''} property_img={props.images ? props.images.url : ''} property_address={props.address_details ? props.address_details : ''} property_bedrooms={props.bedrooms ? props.bedrooms : ''} property_bathrooms={props.bathrooms ? props.bathrooms : ''} officeurl={props.officeurl} />
                </div>
              
              <div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </React.Fragment>
  );
};
export default SendToFriend;
