import React, { useState, useEffect } from "react";
import { Link } from "gatsby";
import { Container, Col, Row } from "react-bootstrap";
import ScrollAnimation from 'react-animate-on-scroll';
import '../Components/SearchResult/SearchList/SearchList.scss'
const PropertyWorthModule = (props) => {
    return (
        <React.Fragment>
            
            <div className={props.title === "Home" ? 'search-list-block home-app-module': "search-list-block blue-full-width"}>                
                <div className="appraisal-wrap">
                <Container>
                    <Row>
                        <Col md="12">
                            <div className="appraisal-bar">
                                <div className="left-block">
                                    <h2>How much is your property worth?</h2>
                                    <p>
                                        Book a free valuation appointment with one of our
                                        experts.
                                    </p>
                                </div>
                                <div className="right-block">
                                    <Link to="/property-valuation/" className="btn">
                                        Book your market appraisal
                                    </Link>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
                </div>
            </div>
            
        </React.Fragment>
    );
};

export default PropertyWorthModule;
