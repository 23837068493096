import React from "react";
import AppStoreIcon from "../../src/images/app-store-badge.png";
import GooglePlayIcon from "../../src/images/google-play-badge.png";

const AppLinks = (props) => {    
    return (
        <React.Fragment>
            <div className="available-apps">
                <div className="app-wrap">
                    {props.androidlink &&
                        <a href={props.androidlink} target="_blank" className="app-btns gplay-icon">
                            <img src={GooglePlayIcon} alt="Android Smart APP - Balgores" />
                        </a>
                    }
                    {props.appstorelink &&
                        <a href={props.appstorelink} target="_blank" className="app-btns">
                            <img src={AppStoreIcon} alt="IOS Smart APP - Balgores" />
                        </a>
                    }
                </div>
            </div>            
        </React.Fragment>
    );
};

export default AppLinks;
