import React, { useState } from "react";
import { Link } from "gatsby"
import { Container, Row, Col } from "react-bootstrap"
import ScrollAnimation from 'react-animate-on-scroll';
import parse from 'html-react-parser';
import { getSrc } from "gatsby-plugin-image"
import { AltSiteName } from "../../../Components/utils"
import "./About.scss"
import GetCTA from "../../../Components/getcta"
import GetThumbnailLink from "../../get-thumbnail-link";
// Header component

const ImageGrid = (props) => {
    const MAX_LENGTH = 150;
    var imagelength = props.grids && props.grids.length;
    return (
        <React.Fragment>
            <section className="gridimage about-section pt-0">            
                <Container>
                <Row>
                    <Col xl={12}>
                        {props.section_title &&
                        <div className="block-heading">
                            <h2>{props.section_title}</h2>
                            {props.intro_text && <p>{props.intro_text}</p>}
                        </div>
                        }
                    </Col>
                </Row>
                    <Row className="grid-items-row">
                    {props.grids && props.grids.map((grid,index) => {
                        var oddeven = index+1;
                        return<>
                        <Col md="6" lg={imagelength > 2 ?"4":"6"} className={(oddeven % 2  == 0) ? "pl grid-wrap" : "pr grid-wrap"}>
                        <div className={imagelength > 2 ?"about-block landing-grid":"about-block home-block"}>
                            { props.renderComponent &&
                                <picture class="img-zoom">     
                                    {(grid.Grid_Custom_Link && grid.Link_New_Tab === false)?
                                    <Link to={grid.Grid_Custom_Link}>                     
                                    <img src={getSrc(grid.Grid_Image.url_sharp) } alt={grid.Grid_Image.alternativeText?grid.Grid_Image.alternativeText:grid.Grid_Title+AltSiteName} />
                                    </Link>
                                    :
                                    (grid.Grid_Custom_Link && grid.Link_New_Tab === true)?
                                    <a href={grid.Grid_Custom_Link} target="_blank">                     
                                    <img src={getSrc(grid.Grid_Image.url_sharp) } alt={grid.Grid_Image.alternativeText?grid.Grid_Image.alternativeText:grid.Grid_Title+AltSiteName} />
                                    </a>
                                    : 
                                    <GetThumbnailLink image={getSrc(grid.Grid_Image.url_sharp) } alt={grid.Grid_Image.alternativeText?grid.Grid_Image.alternativeText:grid.Grid_Title+AltSiteName} link={grid.Grid_Link && grid.Grid_Link.Primary_URL} />
                                    }
                                    { props.pageclass == 'developer-landing-page' &&
                                        <img src={getSrc(grid.Grid_Image.url_sharp) } alt={grid.Grid_Image.alternativeText?grid.Grid_Image.alternativeText:grid.Grid_Title+AltSiteName} />
                                    }
                                </picture>
                            }
                            <div className="about-text">
                                <h2>{grid.Grid_Title}</h2>
                                {/* {grid.Grid_Content.length > MAX_LENGTH ?
                                    (
                                        parse(grid.Grid_Content.substring(0, MAX_LENGTH)+'...')
                                    
                                    ) :
                                    parse(grid.Grid_Content)
                                }                                 */}
                                {parse(grid.Grid_Content)}
                            </div>
                            {(grid.Grid_Custom_Link && grid.Link_New_Tab === false)?
                                <Link to={grid.Grid_Custom_Link} className="link-text">{grid.Grid_Link_Label}</Link>
                            :
                            (grid.Grid_Custom_Link && grid.Link_New_Tab === true)?
                                <a target= "_blank" href={grid.Grid_Custom_Link} className="link-text">{grid.Grid_Link_Label}</a>
                            :                        
                            <GetCTA Label={grid.Grid_Link_Label} link={grid.Grid_Link && grid.Grid_Link.Primary_URL} class="link-text"/>
                            }
                        </div>
                        </Col>
                        </>
                        })}                        
                    </Row>
                </Container>
            
            </section>
        </React.Fragment>
    )
}
export default ImageGrid
