import { Link } from "gatsby";
import React, { useEffect, useState, useRef } from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
// import AddressSearch from 'react-loqate';
import loadable from '@loadable/component'
import $ from 'jquery'
import Select from "react-dropdown-select";

const reactLoquate = loadable.lib(() => import('react-loqate'))
// console.log('aaaaaaaaaaa',reactLoquate)
const LoqateAdress = loadable(() => import('./address-search'))     
// LoqateAdress.load().then(() => {
//   console.log('Component is loaded!')
// })
const AddressSearchInput = (props) => {
    return (
            <div className="custom-float">                
                <input
                    name="address"
                    placeholder="Start typing your address..."
                    autocomplete="off"
                    type="text"
                    id="address"
                    class="form-control"
                    {...props}
                    value={props.addressLabel}
                    required="true"
                />
  
            </div>
    );
  };
  const handleAddress = (address) => {
    document.getElementById('address').value = address.Label.replace(/\n/g, ", ")
    document.getElementById('addressBuildingName').value = address.BuildingName
    document.getElementById('addressSecondaryStreet').value = address.SecondaryStreet
    document.getElementById('addressBuildingNumber').value = address.BuildingNumber
    document.getElementById('addressPostalCode').value = address.PostalCode
    document.getElementById('addressSubBuilding').value = address.SubBuilding
    document.getElementById('addressStreet').value = address.Street
  }
const InstantValuationSteps = (props) => {

    const Valpal = loadable(() => import('./valpal-result'))
    
    
    const [username, setUsername] = useState('')
    const [useremail, setUseremail] = useState('')
    const [userphone, setUserphone] = useState('')
    const [valtype, setValtype] = useState('')
    const [valbedrooms, setValbedrooms] = useState('')
    const [valPtype, setValPtype] = useState('')
    const [addressLabel, setAddressLabel] = useState('')
    const [addressBuildingName, setAddressBuildingName] = useState('')
    const [addressSecondaryStreet, setAddressSecondaryStreet] = useState('')
    const [addressBuildingNumber, setAddressBuildingNumber] = useState('')
    const [addressPostalCode, setAddressPostalCode] = useState('')
    const [addressSubBuilding, setAddressSubBuilding] = useState('')
    const [addressStreet, setAddressStreet] = useState('')
    //STEP 1
    const Validatestep1 = () => {
      setValbedrooms($("input[name=bedrooms]").val());
      setValPtype($("input[name=propertytype]").val());
      setAddressBuildingName($("input[name=addressBuildingName]").val());
      setAddressSecondaryStreet($("input[name=addressSecondaryStreet]").val());
      setAddressBuildingNumber($("input[name=addressBuildingNumber]").val());
      setAddressPostalCode($("input[name=addressPostalCode]").val());
      setAddressSubBuilding($("input[name=addressSubBuilding]").val());
      setAddressStreet($("input[name=addressStreet]").val());
      var valaddress   = $("input[name=address]").val();
      var valbedroom   = $("input[name=bedrooms]").val();
      var valpropertytype  = $("input[name=propertytype]").val();      
      var enqtype = $('input:radio[name=enquirytype]:checked').val();
      var typeaddress   = $("input[name=addressPostalCode]").val();
      if(valaddress == '' || typeaddress.length === 0) {
        $('.steps-error').html('');
        $('.address-search-box').addClass('field-invalid');
        $('.steps-error').html("<div class='alert-error'><p>Highlighted fields are required.</p></div>");
      } else {
        $('.address-search-box').removeClass('field-invalid');        
      }
      if(valbedroom == '' || valbedroom.length === 0) {
        $('.steps-error').html('');
        $('.react-dropdown-select.bedrooms').addClass('field-invalid');
        $('.steps-error').html("<div class='alert-error'><p>Highlighted fields are required.</p></div>");
      } else {
        $('.react-dropdown-select.bedrooms').removeClass('field-invalid');        
      }
      if(valpropertytype == '' || valpropertytype.length === 0) {
        $('.steps-error').html('');
        $('.react-dropdown-select.proptypes').addClass('field-invalid');
        $('.steps-error').html("<div class='alert-error'><p>Highlighted fields are required.</p></div>");
      } else {
        $('.react-dropdown-select.proptypes').removeClass('field-invalid');        
      }
      if(enqtype == '' || enqtype == undefined) {
        $('.steps-error').html('');
        $('.instant-val-form .form-wrapper').addClass('field-invalid');
        $('.steps-error').html("<div class='alert-error'><p>Highlighted fields are required</p></div>");
      } else {
        $('.instant-val-form .form-wrapper').removeClass('field-invalid');        
      }
      if(valaddress !="" &&  typeaddress.length !== 0 && enqtype !="" && enqtype != undefined && valbedroom != '' && valbedroom.length != 0 && valpropertytype != '' && valpropertytype.length != 0 ) {
        $('.steps-error').html('');
        $('.instant-val-form .step-1').hide();
        $('.instant-val-form .step-2').css("display", "block");
      }
    }
    //STEP 2
    const Validatestep2 = () => {
      var valname   = $("input[name=name]").val();
      // set name and type
      setUsername ($("input[name=name]").val());
      setUseremail ($("input[name=email]").val());
      setUserphone ($("input[name=telephone]").val());
      setValtype($('input:radio[name=enquirytype]:checked').val());
      // set name and type      
    }
    const Goback = () => {
      $('.instant-val-form .step-2').hide();
      $('.instant-val-form .step-1').show();
    }
    const Bedrooms = [
      { value: "Studio", label: "Studio" },
      { value: "1", label: "1" },
      { value: "2", label: "2" },
      { value: "3", label: "3" },
      { value: "4", label: "4" },
      { value: "5", label: "5" },
      { value: "6+", label: "6 +" },
    ];
    const PropertyType = [
      { value: "Flat", label: "Flat" },     
      { value: "Terraced House", label: "Terraced House" },      
      { value: "Semi Detached House", label: "Semi Detached House" },      
      { value: "Detached House", label: "Detached House" },      
      { value: "Semi Detached Bungalow", label: "Semi Detached Bungalow" },      
      { value: "Detached Bungalow", label: "Detached Bungalow" },      
 
    ];
    //console.log('aaaaaaaa', valbedrooms, valPtype)
    return (
        <React.Fragment>

            {props.showthankyou &&
            <div className="val-thank-you">
              <h3>Thank you {username}. <br />Here are your estimated {valtype == "both"?"Sale and Rent":valtype} Values.</h3>
              <h3 className="errorvalpal">Unfortunately, we were unable to generate any data for that address. If you speak to a member of our team, we will be happy to help you with your valuation. Please click below to arrange a free, no obligation market appraisal with our team.</h3>
              <p>Fetching...</p>
            </div>
            }
            {props.showthankyou &&
            <div className="valpal-list-api">
              <Valpal enqtype={valtype} addressBuildingName={addressBuildingName}  addressSecondaryStreet={addressSecondaryStreet} addressBuildingNumber={addressBuildingNumber} addressPostalCode={addressPostalCode} addressSubBuilding={addressSubBuilding} addressStreet={addressStreet} bedrooms={valbedrooms} propertytype={valPtype} name={username} phone={userphone} email={useremail} />
            </div>
            }
            

            <input id="addressBuildingName" type="hidden" name="addressBuildingName" />
            <input id="addressSecondaryStreet" type="hidden" name="addressSecondaryStreet" />
            <input id="addressBuildingNumber"  type="hidden" name="addressBuildingNumber" />
            <input id="addressPostalCode"  type="hidden" name="addressPostalCode" />
            <input id="addressSubBuilding"  type="hidden" name="addressSubBuilding" />
            <input id="addressStreet"  type="hidden" name="addressStreet" />

            <Form.Group className="mb-3 address step-1" controlId="formBasicEmail">
              <input id="addressLabel" value="" type="hidden" name="addressLabel" />
              <LoqateAdress addressLabel={addressLabel}/>
                      {/* <AddressSearch
                        locale="en_GB"
                        apiKey={process.env.GATSBY_LOQATE_API_KEY}
                        countries={['GB']}
                        components={{ Input: AddressSearchInput}}
                        className="address-search-box"
                        inputClassname="address form-control"
                        listClassname="address-options"
                        listItemClassname="address-option"
                        onSelect={(address) => handleAddress(address)}
                        /> */}
                      
            </Form.Group>
            <Form.Group className="mb-3 address step-1" controlId="formBasicEmail">
                      <Select className="bedrooms form-control" name="bedrooms" placeholder="Bedrooms" searchable={false}
                        options={Bedrooms}
                        values={[]}
                      />
            </Form.Group>
            <Form.Group className="mb-3 address step-1" controlId="formBasicEmail">
                      <Select className="proptypes form-control" name="propertytype" placeholder="Property Type" searchable={false}
                        options={PropertyType}
                        values={[]}
                      />
            </Form.Group>
            <button onClick={()=> Validatestep1()} type="button" className="clickinstant w-md-100 mtop-btn  btn btn-primary btn step-1">
                        NEXT STEP
            </button>
            <Form.Group className="mb-3 address step-2" controlId="formBasicEmail">
                      <Form.Control required type="text" name="name" placeholder="Your name" />
                      
            </Form.Group>
            <Form.Group className="mb-3 address step-2" controlId="formBasicEmail">
                      <Form.Control required type="email" name="email" placeholder="Your email" />
            </Form.Group>
            <Form.Group className="mb-3 address step-2" controlId="formBasicEmail">
                      <Form.Control required pattern="^[+]*[0-9-()]+(\s+[0-9-()]+)*.{9}$" type="number" name="telephone" placeholder="Your phone number" />                      
            </Form.Group>
            <div className="agree-val step-2">By submitting the form, you agree to our <a target="_blank" href ="/terms-and-conditions/">Terms and Conditions</a> and <a target="_blank" href ="/privacy-policy/">Privacy Policy</a>.</div>
            <button onClick={()=> Validatestep2()} type="sumbit" className="submit-val-action clickinstant w-md-100 mtop-btn  btn btn-primary btn step-2">
              SUBMIT
            </button>
            <button onClick={()=> Goback()} type="button" className="back-val-action clickinstant w-md-100 mtop-btn  btn btn-primary btn step-2">
              GO BACK
            </button>
        </React.Fragment>
    )    
}
export default InstantValuationSteps;
