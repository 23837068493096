import React, { useState } from "react";
import { Link } from "gatsby"
import ScrollAnimation from 'react-animate-on-scroll';
import { Container, Row, Col, Card } from "react-bootstrap"
import Accordion from "react-bootstrap/Accordion"
import AccordionContext from "react-bootstrap/AccordionContext"
import { useAccordionToggle } from "react-bootstrap/AccordionToggle"
import { useContext } from "react"
import parse from 'html-react-parser';
import "./Work.scss"

const TileAccordion = (props) => {
    const [isPlay, setPlay] = useState(false);

    function ContextAwareToggle({ children, eventKey, callback }) {
        const currentEventKey = useContext(AccordionContext)

        const decoratedOnClick = useAccordionToggle(
            eventKey,
            () => callback && callback(eventKey)
        )
        const isCurrentEventKey = currentEventKey === eventKey

        return (
            <button
                type="button"
                className={
                    isCurrentEventKey ? "active_card inactive_card" : "inactive_card"
                }
                onClick={decoratedOnClick}
            >
                {children}
                <i
                    className={
                        isCurrentEventKey ? "icon-minus-footer " : "icon-plus-footer"
                    }
                ></i>
            </button>
        )
    }
    return (
        <React.Fragment>
            <section className="work-section tile-accordion pt-0 pb-0">

                <div className="about-work pt-0 pb-0">
                    <div className="accordion-section">
                        <Accordion >
                            {props.accordion && props.accordion.map((item) => {
                                return <>
                                    <Card>
                                        <div className="accordion-header card-header">
                                            <ContextAwareToggle eventKey={item.id} className="accordion-header">
                                                <h3>{item.Accordion_Title}</h3>
                                            </ContextAwareToggle>
                                        </div>
                                        <Accordion.Collapse eventKey={item.id}>
                                            <div className="accordion-card accordion-card-first card-body">
                                                {parse(item.Accordion_Content)}
                                            </div>
                                        </Accordion.Collapse>
                                    </Card>
                                </>
                            })}

                        </Accordion>
                    </div>
                </div>

            </section>

        </React.Fragment>

    )
}
export default TileAccordion
