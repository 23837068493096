import React, { useState } from "react";
import { Container, Row, Col, Modal } from "react-bootstrap";
import BookAppointment from "../../ValuationForm/ValuationInfo/BookanAppointmentForm";
import "./ValuationInfo.scss";
import parse from 'html-react-parser';
import GetCTA from "../../getcta";
const ValuationInfo = (props) => {
  const [showModalId, setShowModalId] = useState(null);

  const handleShow = (dialogId) => setShowModalId(dialogId);
  const handleClose = () => setShowModalId(null);

  return (
    <React.Fragment>
      <section className="valuation-info">
        <Container>
          <Row>
            <Col>
              <div className="info">
                <h1>{props.title}</h1>
                {parse(props.description)}

                <div className="box-wrapper">
                  {props.boxes && props.boxes.map((box, index) => {
                    return (
                      <div className="valuation-box" key={index}>
                        <div className="icons">
                          <i className={box.Valuation_Box_Icon_Class}></i>
                        </div>
                        <h3>{box.Valuation_Box_Title}</h3>
                        {parse(box.Valuation_Box_Description)}
                        {box?.Valuation_Box_CTA_Hash ? (
                          <button
                            type="button"
                            className="btn btn-primary"
                            onClick={() => handleShow(box?.Valuation_Box_CTA_Hash)}
                          >
                            {box?.Valuation_Box_CTA_Label}
                          </button>
                        ) : (
                          <GetCTA 
                            class="btn btn-primary" 
                            Label={box.Valuation_Box_CTA_Label} 
                            link={box.Valuation_Box_CTA_Link && box.Valuation_Box_CTA_Link.Primary_URL} 
                          />
                        )}
                      </div>
                    );
                  })}
                </div>

              </div>
            </Col>
          </Row>
        </Container>
        <Modal 
          show={showModalId === '#mortgage-advice-booking'} 
          onHide={handleClose} 
          Id="mortgage-advice-booking" 
          dialogClassName="book-appointment-modal"
        >
          <Modal.Header closeButton>
          </Modal.Header>
          <Modal.Body>
            <BookAppointment/>
          </Modal.Body>
        </Modal>
      </section>
    </React.Fragment>
  );
};
export default ValuationInfo;
