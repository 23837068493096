import React, {useState,useEffect} from "react";
import {graphql } from 'gatsby'
import 'bootstrap/dist/css/bootstrap.min.css';
import "../scss/bootstrap-overrides.scss";
import "../scss/global.scss";
import "../scss/grunticon.scss";
import Header from "../Components/Header/Header";
import Footer from "../Components/Footer/Footer";
import BreadCrumbs from '../Components/PropertyDetails/BackButton/BreadCrumbs';
import ValuationInfo from "../Components/ValuationForm/ValuationInfo/ValuationInfo";
import BookaValuationForm from "../Components/ValuationForm/ValuationInfo/ValuationForm";
import InstantValuationForm from "../Components/ValuationForm/ValuationInfo/InstantValuationForm";
import MemberContact from "../Components/ValuationForm/ValuationInfo/MemberContact";
import FirstTimeBuyer from "../Components/ValuationForm/ValuationInfo/FirstTimeBuyer";
import HomeMovers from "../Components/ValuationForm/ValuationInfo/HomeMovers";
import BookAViewingForm from "../Components/ValuationForm/ValuationInfo/BookaViewingForm";
import SendToFriendForm from "../Components/ValuationForm/ValuationInfo/SendToFriendForm";
import ReferAFriendForm from "../Components/ValuationForm/ValuationInfo/ReferaFriendForm";
import BookAppointment from "../Components/ValuationForm/ValuationInfo/BookanAppointmentForm";
import { useLocation } from "@reach/router"
import { parse } from "query-string"
import {OfficeBaseURL, BranchURL} from "../Components/utils"
import { MemberContactDetail, GetPropertyBooking, GetPropertyOffice } from "../queries/common_use_query"
import BuyToLetMemberContact from "../Components/ValuationForm/ValuationInfo/BuyToLet";

const FormTemplate = (props) => {
    const GQLPage = props.GQLPage;
    const GQLModules = props.GQLModules;
    const GQLConfig = props.GQLConfig; 
    const location = useLocation();    
    const searchParams = parse(location.search) // => {init: "true"}
    const teamid = searchParams.tid
    const pid = searchParams.pid
    const enqtype = searchParams.type
    const postcode = searchParams.postcode
    const {loading, error, data} = MemberContactDetail(teamid);
    const {loading:prop_loading, error:prop_error, data:prop_data} = GetPropertyBooking(pid);
    // TEAM DETAILS
    var membername = data && data.teams.length > 0 ? data.teams[0].Name:"";
    var memberemail = data && data.teams.length > 0 ? data.teams[0].Email:"";
    //PROPERTY DETAILS
    var searchtype = prop_data && prop_data.properties.length > 0 ? prop_data.properties[0].search_type:"";
    var displayaddress = prop_data && prop_data.properties.length > 0 ? prop_data.properties[0].display_address:"";
    var propertyurl= ""
    var propertyslug = ""
    var officeemailsales= ""
    var officeemaillettings= ""
    if(prop_data && prop_data.properties.length > 0) {
        propertyurl =   searchtype=="sales"?"/property-for-sale/":"/property-to-rent/"
        propertyslug =  prop_data.properties[0].slug+'-'+prop_data.properties[0].id;
    }
    const {loading:branch_loading, error:branch_error, data:branch_data} = GetPropertyOffice(prop_data && prop_data.properties.length > 0 && prop_data.properties[0].office_crm_id);
    var officeurl = branch_data && branch_data.ourOffices && branch_data.ourOffices.length > 0 && branch_data.ourOffices[0].URL
    officeemailsales = branch_data && branch_data.ourOffices && branch_data.ourOffices.length > 0 && branch_data.ourOffices[0].Sales_Email_Address
    officeemaillettings = branch_data && branch_data.ourOffices && branch_data.ourOffices.length > 0 && branch_data.ourOffices[0].Lettings_Email_Address
    //console.log('ssssss', teamid)
    const wrapClass = `page-${GQLPage?.Title.toLowerCase().replace(/\s+/g, '-')}`;
    return (
        <div id={`body-bg-default`} className={`wrapper  form-template ${wrapClass}`}>
        <React.Fragment>
        <div className="inner-header"><Header/></div>
        {(teamid && teamid!="first-time-buyer" && teamid!="home-movers" && teamid!="buy-to-let") ?
        <BreadCrumbs baselabel= "Meet the team" baseurl="/about/meet-the-team/" itemlabel={membername}/>
        :
        (teamid && teamid=="first-time-buyer") ?
        <BreadCrumbs baselabel= "First time buyer" baseurl="/financial-services/first-time-buyer/" itemlabel={membername}/>
        :
        (teamid && teamid=="home-movers") ?
        <BreadCrumbs baselabel= "MOVING HOME" baseurl="/financial-services/moving-home/" itemlabel={membername}/>
        :
        (teamid && teamid=="buy-to-let") ?
        <BreadCrumbs baselabel= "BUY TO LET" baseurl="/financial-services/buy-to-let/" itemlabel={membername}/>
        :
        (pid)?
        <BreadCrumbs baselabel= {searchtype=="sales"?"Property for sale":"Property to let"} baseurl={searchtype=="sales"?"/properties-for-sale/essex-and-kent/":"/properties-to-rent/essex-and-kent/"} itemlabel={displayaddress} property_url={location.origin+propertyurl+propertyslug}/>
        :
        <BreadCrumbs/>
        }
        {GQLModules.map((Modules, i) => {
        return<>
            {Modules.__typename === "GLSTRAPI_ComponentComponentsValuationPage" &&
                <ValuationInfo title={Modules.Valuation_Page_Heading} description={Modules.Valuation_Page_Description} boxes={Modules.Valuation_Box_Snippet}/>
            }
            {Modules.__typename === "GLSTRAPI_ComponentComponentsForms" && Modules.Select_Forms === "Book_A_Valuation_Form" &&
                <BookaValuationForm postcode={postcode} postcode_mapping={GQLConfig.Email_Postcode_Mapping} />
            }
            {Modules.__typename === "GLSTRAPI_ComponentComponentsForms" && Modules.Select_Forms === "Instant_Online_Valuation_Form" &&
                <InstantValuationForm postcode_mapping={GQLConfig.Email_Postcode_Mapping} />
            }
            {Modules.__typename === "GLSTRAPI_ComponentComponentsForms" && Modules.Select_Forms === "Member_Contact" && teamid!=="first-time-buyer" && teamid!=="home-movers" && teamid!=="buy-to-let" &&
                <MemberContact name={membername} email={memberemail} />
            }
            {Modules.__typename === "GLSTRAPI_ComponentComponentsForms" && Modules.Select_Forms === "Member_Contact" && teamid==="first-time-buyer" &&
                <FirstTimeBuyer name={membername} email={memberemail} />
            }
            {Modules.__typename === "GLSTRAPI_ComponentComponentsForms" && Modules.Select_Forms === "Member_Contact" && teamid==="home-movers" &&
                <HomeMovers name={membername} email={memberemail} />
            }
            {Modules.__typename === "GLSTRAPI_ComponentComponentsForms" && Modules.Select_Forms === "Member_Contact" && teamid==="buy-to-let" &&
                <BuyToLetMemberContact name={membername} email={memberemail} />
            }
            {Modules.__typename === "GLSTRAPI_ComponentComponentsForms" && Modules.Select_Forms === "Book_A_Viewing_Form" && prop_data && prop_data.properties.length > 0 &&
                <BookAViewingForm enqtype={enqtype} toemail={searchtype=="sales"?officeemailsales:officeemaillettings} property_url={location.origin+propertyurl+propertyslug} address_details={displayaddress} id={prop_data.properties[0].id} title={displayaddress} price={prop_data.properties[0].price} type={prop_data.properties[0].title} telephone= "" images={prop_data.properties[0].images && prop_data.properties[0].images[0]} bedrooms={prop_data.properties[0].bedroom} bathrooms={prop_data.properties[0].bathroom} officeurl={officeurl?location.origin+OfficeBaseURL+officeurl:location.origin+BranchURL}  />
            }
            {Modules.__typename === "GLSTRAPI_ComponentComponentsForms" && Modules.Select_Forms === "Send_To_Friend_Form" && prop_data && prop_data.properties.length > 0 &&
                <SendToFriendForm toemail={searchtype=="sales"?officeemailsales:officeemaillettings} property_url={location.origin+propertyurl+propertyslug} address_details={displayaddress} id={prop_data.properties[0].id} title={displayaddress} price={prop_data.properties[0].price} type={prop_data.properties[0].title} telephone= "" images={prop_data.properties[0].images && prop_data.properties[0].images[0]} bedrooms={prop_data.properties[0].bedroom} bathrooms={prop_data.properties[0].bathroom} officeurl={officeurl?location.origin+OfficeBaseURL+officeurl:location.origin+BranchURL}  />
            }
            {Modules.__typename === "GLSTRAPI_ComponentComponentsForms" && Modules.Select_Forms === "Refer_A_Friend_Form" &&
                <ReferAFriendForm  />
            }
            {Modules.__typename === "GLSTRAPI_ComponentComponentsForms" && Modules.Select_Forms === "Book_An_Appointment_Form" &&
                <BookAppointment /> 
            }
        </>
        })}
        <Footer/>
        </React.Fragment>
        </div>
    )
}
export default FormTemplate;
