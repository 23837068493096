import React from "react";
import { Link } from "gatsby";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import "./ValuationInfo.scss";
import ReferFriendForm from '../../forms/refer-a-friend';

const ReferFriend = () => {
  return (
    <React.Fragment>
      <section className="valuation-info book-appointment-form refer-friend-form" id="refer-a-friend-form">
        <Container>
          <Row>
            <Col>
                <div className="valuation-box book">
                  <Link className="icons">
                    <i className="icon-callback">
                    </i>
                  </Link>
                  <h3>Recommend a Friend</h3>
                  <p>Please fill in the details below, and one of our advisers will be in touch.</p>
                  <ReferFriendForm />
                </div>
              
              <div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </React.Fragment>
  );
};
export default ReferFriend;