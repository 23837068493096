import React, { useState } from "react"
import { Link } from "gatsby"
import { Container, Row, Col } from "react-bootstrap"
import "./WorkNumber.scss"
import parse from 'html-react-parser';
import GetCTA from "../../getcta";
import { getSrc } from "gatsby-plugin-image"
import { AltSiteName } from "../../utils";
import getVideoId from "get-video-id";
import PlayVideo from "../../Play/PlayVideo";
// Header component

const LeftTile = (props) => {
    const [isPlay, setPlay] = useState(false);
    const videoid = props.video && getVideoId(props.video);
    var pattern = /^((http|https|ftp):\/\/)/;
    var target= ""
    if(pattern.test(props.customlink)) {
        target = "_blank";
    }  
    return (
        <React.Fragment>
            <div id={`tile-bg-${props.bgcolor?props.bgcolor:"default"}`} className={props.style === "Medium"?"mortage-services tileblock tile-block-left "+props.style:"work-number tileblock"}>                
                

                    <Container>
                        <Row className="align-items-lg-center">
                            <Col lg="6">
                            <div className="mlr-minus">
                            {/* { props.renderComponent && */}
                            <picture className="img-zoom">
                                    <img src={getSrc(props.image.url_sharp)}  alt={props.image.alternativeText?props.image.alternativeText:props.title+AltSiteName}/>
                            </picture>
                            {/* } */}
                            {props.video?
                            <button
                            type="button"
                            className="btn-play"
                            onClick={(e) => {
                            setPlay(true);}}>
                            <i className="icon-play"></i>
                            </button>
                            :""}
                            </div>
                            </Col>
                            <Col lg="6">
                                
                               <div className={props.style === "Medium"?"services-content":"average-number"}>
                                    {props.title &&
                                        <span className="small-title">{props.title}</span>
                                    }
                                    {parse(props.content)}
                                    {props.customlink && target &&
                                        <a href={props.customlink} target="_blank" className="link-text">{props.label}</a>
                                    }
                                    {props.customlink && !target ?
                                    <Link to={props.customlink} className="link-text">{props.label}</Link>
                                    :
                                    <GetCTA Label={props.label} link={props.link && props.link.Primary_URL} class="link-text"/>
                                    }
                                    {props.counter &&
                                    <ul>
                                        {props.counter.map((item,index) => {
                                        return<>
                                            <li>
                                                <div className="number-text">
                                                    <span>{item.Value}</span>
                                                    <small>{item.Value_Type}</small>
                                                </div>
                                                <div className="average-text">
                                                    {parse(item.Value_Content)}                                                    
                                                </div>
                                            </li>
                                        </>
                                        })}                                                                         
                                   </ul>
                                    }
                               </div>
                            </Col>
                        </Row>
                    </Container>
                
            </div>
            {videoid && props.video &&
      <PlayVideo
        isOpen={isPlay}
        isCloseFunction={setPlay}
        videoId={videoid.id}
        isAutoPlay={1}
      />
      }
        </React.Fragment>
    )
}
export default LeftTile
