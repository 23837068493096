import React from 'react';
import Img01 from '../images/Office/contact-img.jpg';
import ContactImg1400 from '../images/Office/contact-img1400.jpg';
import ImageTransform from "./common/ggfx-client/module/components/image-transform";
import {AltSiteName, TeamBaseURL} from './utils'
import { Link } from "gatsby"

const GetStaff = (props) => {
    var team = props.member
    let processedImages = JSON.stringify({});
    if (team?.imagetransforms?.Staff_Image_Transforms) {
    processedImages = team.imagetransforms.Staff_Image_Transforms;
    }
    if(team) {
    return (          
      <React.Fragment>     
        <div className={props.class?props.class:"contact-details"}>          
          <div className="contact-wrapper">
            <div className="contact-img">
            <ImageTransform imagesources={team.Staff_Image && team.Staff_Image.url} renderer="srcSet" imagename="teams.Staff_Image.sidebartile" attr={{ alt: team.Staff_Image.alternativeText?team.Staff_Image.alternativeText:team.Name+' - '+team.Designation+AltSiteName}} imagetransformresult={processedImages} id={team.id}/>                           
            </div>
            <div className={props.contactclass?props.contactclass:"contact-info "}>
              <h3>{team.Name}</h3>
              <span className="profile">{team.Designation}</span>
              <span>
                <Link to={`/contact?tid=${team.id}`} className="dark-text">Send email</Link> or <insidespan className="call-right-span">call
                <a href={`tel:${team.Phone_Number}`} className="tel">{team.Phone_Number}</a></insidespan>
              </span>
            </div>
          </div>   
          {/* {!props.hidecta &&
            <Link to={`${TeamBaseURL}/${team.URL}`} className="btn btn-primary">Contact Us</Link>    
          } */}
          {!props.hidecta &&
          <Link to={`/branches/`} className="btn btn-primary">Contact Us</Link> 
          }
          </div>     
      </React.Fragment>
      )
    } else {
      return (
        <empty></empty>
      )
    }
  };
  export default GetStaff;